// i18next-extract-mark-ns-start application-engineering-services

import { graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Aside from '@components/Aside';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const applicationEngineeeringservicesPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t('seo_title')}
				description={t('seo_description')}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="wide">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>
							Accelerating simulation software implementation and effectiveness
						</h2>
						<p>
							Realis Simulation’s Application Engineering service assists clients with on-boarding and leveraging our software tools,
							to ensure maximum return on investment. Our experienced engineers help customers improve deployment of simulation tools
							through process development, custom training and real-world application on scalable engineering projects.
						</p>
						<p>
							The benefits of this service are substantial.
							Our approach goes beyond the conventional model of customer software evaluation and user training.
							We work closely with clients to define project objectives, teach analysis techniques and accelerate successful application of software –
							providing 5 areas of added value, <strong>putting engineers in control</strong> of their simulation tools.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="Application Engineering Services" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<p>
							With a global team of engineers located in the USA, Asia and Europe, our application engineering team have over 80 years of consulting experience
							in manufacturer and Original Equipment Manufacturers (OEM) environments.
						</p>
						<p>
							Formerly Ricardo Software, Realis Simulation has 30 years of software heritage and is ideally placed to provide highly trained and experienced engineers
							that can further demonstrate technical consulting applications specifically using our tools.
						</p>
						<p>Typical client engagements include:</p>
						<ul>
							<li>
								<strong>Kick-starter engineering services:</strong>
								&nbsp;Accelerate the adoption and increase the
								return on investment of our tools within your
								company. With the help of our engineering experts,
								we assist with customizing model libraries, teaching
								new methods, developing custom analysis processes
								and establishing efficient workflows with custom
								scripts and templates.
							</li>
							<li>
								<strong>Engineering project services:</strong>
								&nbsp;Our engineering experts demonstrate the
								features and usability of our software on client
								projects.&nbsp; This type of engineering service
								provides both an excellent demonstration of Realis
								Simulation tools and real-world analysis to assess
								and improve your designs.
							</li>
							<li>
								<strong>Funded development:&nbsp;</strong>Fast-track
								your research and modelling concepts by taking advantage
								of Realis Simulation’s funded development programme.
								Armed with your concepts and our development expertise,
								we can ensure your ideas are realized. With product
								releases scheduled twice a year, we rapidly implement,
								test and release new features.
							</li>
							<li>
								<strong> Customized training workshops:&nbsp; </strong>
								Our training workshops enable customers to maximize
								effectiveness and value for specific software
								solutions. We offer regional classes as well as
								customized on-site training, based on client needs.
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget>
						<ContactForm name="contact" title={t("_GetInTouch")} />
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default applicationEngineeeringservicesPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["application-engineering-services", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}	
	banner: file(relativePath: { eq: "content/singlehero.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/application-engineering/services/Realis-Simulation_1999_ApplicationEngineering.jpg" }) {
		...imageBreaker
	}
}
`;